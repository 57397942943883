.cropperModal{
    display:flex;
    flex-direction:column;
    .preview-button{
        margin-top:30px;
        .cropper-preview{
            flex:1;
            overflow:hidden;
            border:1px solid #e8e8e8; 
        }
    }
}
@primary-color: #0052D9;@layout-body-background: #f1f2f5;