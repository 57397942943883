.mainBox {
  margin: 38px 24px 24px;
  padding: 32px;
  background: #ffffff;
  border-radius: 4px;
  max-height: calc(100% - 62px);
  overflow-y: scroll;
  box-sizing: border-box;
}

/* 去除滚动条 */
.mainBox::-webkit-scrollbar {
  width: 0 !important
}

.mainBox {
  -ms-overflow-style: none;
}

.mainBox {
  overflow: -moz-scrollbars-none;
}

.mainBox2 {
  margin: 38px 24px 24px;
  padding: 0;
  // background: #ffffff;
  border-radius: 4px;
  max-height: calc(100% - 62px);
  overflow-y: scroll;
  box-sizing: border-box;
}


/* 去除滚动条 */
.mainBox2::-webkit-scrollbar {
  width: 0 !important
}

.mainBox2 {
  -ms-overflow-style: none;
}

.mainBox2 {
  overflow: -moz-scrollbars-none;
}

.asideBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  justify-content: space-between;

  .asideLogo {
    flex-shrink: 0;
    padding: 9px 15px;
  }

  .asideContent {
    height: 90%;
    flex: 1;
    border-top: 1px solid #e5e5e7;
    border-bottom: 1px solid #e5e5e7;
    display: flex;

    .asideContentLeft {
      width: 69px;
      padding: 8px;
      border-right: 1px solid #e5e5e7;

      .asideBtns {
        font-size: 14px;
        color: 646466;
        font-weight: 400;
        width: 52px;
        height: 52px;
        border-radius: 8px;
        margin-top: 4px;
        margin-bottom: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all .1s;
        position: relative;

        img {
          width: 16px;
          height: 16px;
        }

        .asideBtnsTxt {
          margin-top: 4px;
        }

        .warnIcon {
          position: absolute;
          top: 4px;
          right: 4px;
          background-color: #e34d59;
          width: 16px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          border-radius: 50%;
          font-size: 12px;
          color: #fff;
          font-weight: lighter;
        }
      }

      .asideBtns:hover {
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.10) inset;
      }

      .asideBtnsActive {
        background-color: #e9e9ee;
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.10) inset;
        color: #1f1f20;
        font-weight: 700;
      }
    }

    .asideContentRight {
      width: 100%;
      padding: 8px 4px;
      height: 95%;
      overflow: scroll;

      .routeName {
        padding: 10px 24px;
        margin-top: 4px;
        margin-bottom: 4px;
        font-size: 14px;
        color: #646466;
        font-weight: 400;
        cursor: pointer;
        transition: all .1s;
      }

      .routeName:hover {
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.10) inset;
      }

      .routeNameActive {
        background-color: #ececee;
        border-radius: 0px 4px 4px 0px;
        font-weight: 700;
        color: #1f1f20;
        position: relative;

        span {
          position: absolute;
          top: 0;
          left: 0;
          width: 4px;
          height: 100%;
          display: inline-block;
          background: #0052d9;
          border-radius: 0px 4px 4px 0px;
        }
      }
    }
  }

  .asidePersonal {
    flex-shrink: 0;
    height: 121px;
    padding: 25px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    color: #646466;
    font-weight: 400;

    .userInfoPanel {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .userPhoto {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        border-radius: 25px;
      }

      .userInfoBox {
        flex: 1;
        padding-left: 8px;

        p:nth-child(1) {
          text-align: left;
          font-size: 14px;
          color: #1f1f20;
          font-weight: 400;
          margin-bottom: 6px;
        }

        p:nth-child(2) {
          text-align: left;
          display: inline-block;
          font-size: 11px;
          color: #ED6A0C;
          font-weight: 400;
          border-radius: 2px;
          background-color: #FFF0E5;
          padding: 1px 2px;
        }
      }

      .userExit {
        display: inline-block;
        width: 40px;
        height: 40px;
        background: url('../assets/images/personal//userExit.png') no-repeat center center;
        background-size: 100%;
        cursor: pointer;
      }
    }

    p {
      text-align: center;
    }
  }

  .spinload {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
  }
}

.navHeader {
  position: relative;
  height: 52px;
  padding: 0px 24px;
  overflow: scroll;
  background-color: #fcfcff;

  .navigateBarBox {
    // overflow-x: scroll;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: flex-start;
    white-space: nowrap;
    width: calc(100% - 48px);

    @navigateBarMinWidth: 120px;

    .navigateBar:hover {
      background: #f3f3f3;
    }

    .navigateBar {
      position: relative;
      flex: 1;
      width: 0;
      max-width: @navigateBarMinWidth;
      font-size: 14px;
      font-weight: 400;
      color: #1F1F20;
      // padding: 0 16px;
      // padding-left: 16px;
      margin-right: 8px;
      // box-sizing: border-box;
      height: 28px;
      line-height: 28px;
      border-radius: 4px;
      display: inline-block;
      display: flex;
      justify-content: center;
      align-items: center;

      .txt {
        padding-left: 16px;
        flex: 1;
        overflow: hidden;
        transition: width 2s;
        cursor: pointer;
      }

      .exitIcon {
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        // margin-left: 12px;
        margin-left: 2px;
        margin-right: 4px;
      }

      .exitIcon:hover {
        background-color: #d4d4d5;
        border-radius: 50%;
        transform: scale(1.0);
      }

      .title {
        display: inline-block;
        position: absolute;
        top: 100%;
        left: 10px;
        color: #fff;
        background-color: rgba(0, 0, 0, .7);
        border-radius: 5px;
        font-size: 12px;
        // height: 26px;
        line-height: 18px;
        min-width: @navigateBarMinWidth;
        padding: 4px 12px;
        text-align: left;
        white-space: normal;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .5);
        // display: none;
        opacity: 0;
        transform: scale(0);
        transition: transform .15s;

        .smallTitle {
          display: block;
          text-align: left;
          padding: 0;
          margin-left: -6px;
          color: #9aa0a6;
          transform: scale(0.89);
        }
      }

      .title::before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-bottom: 5px solid rgba(0, 0, 0, .7);
        bottom: 100%;
        left: 10px;
        // transform: translateX(-50%);
      }
    }

    /* 对第一个导航用css做特殊处理 不动业务代码 */
    .navigateBar:first-child {
      max-width: 50px;
      padding: 0px;
      text-align: center;
      // background-color: #f1f1f1;
      // color: #0052d9;
      cursor: pointer;

      .exitIcon {
        display: none;
      }

      .title {
        display: none;
      }
    }

    .navigateBarActive:first-child {
      color: #0052d9;
    }

    .navigateBarActive:not(:first-child) {
      background-color: #ececee;

      .exitIcon:hover {
        background-color: #d4d4d5;
        border-radius: 50%;
        transform: scale(1.0);
      }
    }

    .navigateBar::after {
      content: '';
      position: absolute;
      right: 14px;
      width: 14px;
      height: 100%;
      // background: linear-gradient(to right, rgba(236,236,238,.5) 10%, rgba(236,236,238,0));
      pointer-events: none;
      // background: linear-gradient(to right, rgba(24, 24, 170, 0.5) 10%, #257c25);
    }
  }

  .navigateBarBox:hover .title {
    // transition: 2s opt;
    transition-delay: 1s;
    opacity: 1;
    transform: scale(1);
  }
}

.navigationTitle {
  height: 48px;
  line-height: 48px;
  padding: 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 10;

  // .navigationTitleIcon {
  //   display: inline-block;
  //   width: 8px;
  //   height: 16px;
  //   background: #0052d9;
  //   border-radius: 0px 8px 8px 0px;
  // }

  .navigationTitleTxt:first-child {
    margin-left: 8px;
    // font-size: 14px;
    // font-weight: 500;
    // text-align: left;
    // color: #1f1f20;
  }
}

.perl {
  height: 52px;
  line-height: 52px;
  background-color: #fff;

  .rigicon {
    width: 30px;
    height: 52px;
    // background: linear-gradient(90deg, rgba(255, 255, 255, 0.00), rgba(0, 0, 0, 0.08));
  }
}

.userInfoPanel2 {
  padding: 16px 12px;
  width: 176px;
  // height: 129px;
  display: flex;
  justify-content: space-between;
  // align-items: center;

  .userPhoto {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 25px;
  }

  .userInfoBox {
    flex: 1;
    padding-left: 8px;

    p:nth-child(1) {
      text-align: left;
      font-size: 14px;
      color: #1f1f20;
      font-weight: 400;
      margin-bottom: 6px;
    }

    p:nth-child(2) {
      text-align: left;
      display: inline-block;
      font-size: 11px;
      color: #ED6A0C;
      font-weight: 400;
      border-radius: 2px;
      background-color: #FFF0E5;
      padding: 1px 2px;
    }
  }

}

.ant-popover-inner-content {
  padding: 0;
}


.exitLogin {
  width: 100%;
  height: 56px;
  line-height: 56px;
  text-align: center;
  font-size: 14px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.90);
  border-top: 1px solid #E5E5E7;
  cursor: pointer;
}

.exitClear {
  width: 100%;
  height: 56px;
  line-height: 56px;
  text-align: center;
  font-size: 14px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
  font-weight: 400;
  color: rgb(227, 77, 89);
  border-top: 1px solid #E5E5E7;
  cursor: pointer;
}
@primary-color: #0052D9;@layout-body-background: #f1f2f5;