.ant-layout {
  height: 100%;
}

.ant-layout-header {
  // position: relative;
  // height: 52px;
  // line-height: 52px;
  // background-color: #fcfcff;
  background-color: #f1f2f5;
  // padding: 0px 24px;
  padding: 0;
  box-sizing: border-box;
}

.ant-layout-sider {
  width: 232px !important;
  min-width: 232px !important;
  max-width: 232px !important;
  background-color: #fff;
  box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  z-index: 1;
}

.ant-layout-content {
  // padding: 24px;
  box-sizing: border-box;
}

/* redio默认样式 */
.ant-radio-button-wrapper {
  border: none;
  height: 24px;
  line-height: 24px;
  padding: 0 10px;
  background-color: #f3f3f3;
  margin: 4px 4px;
  border-radius: 3px;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background: none;
}

.ant-radio-button-wrapper:first-child {
  border: none;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: @blue;
  background: @bc1;
  border: none;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background: none;
}

.ant-radio-button-wrapper:focus-within {
  box-shadow: none;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background-color: @bc1;
  color: @blue;
}

.ant-pagination {
  text-align: right;
}

/* 按钮 */
.ant-btn {
  border-radius: 4px;
}

/* 弹窗 */
.ant-modal-close {
  top: 16px;
}

.ant-modal-header {
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  padding-top: 32px;

  .ant-modal-title {
    font-size: 20px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Bold;
    font-weight: 700;
    color: #000000;
  }
}

.ant-modal-body {
  padding-top: 16px;
  padding-bottom: 16px;

  .ant-form-item-no-colon {
    font-size: 14px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
    text-align: right;
    color: #000000;
  }

  .ant-form-item:last-child {
    margin-bottom: 0;
  }
}

.ant-modal-footer {
  border-top: none;
  padding-top: 8px;
  padding-bottom: 32px;

  .ant-btn-default {
    border: none;
    font-size: 14px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.90);
  }

  .ant-btn-primary {
    background: #0052d9;
    border-radius: 3px;
    font-size: 14px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
    color: #ffffff;
  }
}

.ant-modal-content {
  border-radius: 4px;
}

/* 小弹窗 */
.ant-popover-inner {
  .ant-btn-default {
    border: none
  }
}

/* 选择框样式 */
.ant-select-item-option-selected {
  color: #0052D9 !important;
  background-color: #ECF2FE !important;
}

/* 选择框hover */
.ant-select-item-option-active {
  color: #0052D9 !important;
}

.ant-select-dropdown {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.sbdes {
  .ant-input {
    border: none !important;
    font-size: 14px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
    color: #1f1f20;
  }

  .ant-select {
    width: 100% !important;
    font-size: 14px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
    color: #1f1f20;
  }

  .ant-select-selector {
    border: none !important;
    font-size: 14px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
    color: #1f1f20;
  }

  .ant-picker {
    border: none !important;
    width: 100% !important;
    font-size: 14px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
    color: #1f1f20;
  }

  .ant-image-preview-wrap {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }

  .ant-descriptions-item-label {
    width: 165px;
    min-width: 165px;
    color: #95969A;
    font-size: 14px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
  }

  .ant-modal-title {
    font-size: 20px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Bold;
    font-weight: 700;
    color: #000000;
  }

  .ant-descriptions-header {
    font-size: 16px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Bold;
    font-weight: 700;
    color: #000000;
    background-color: #fafafa;
    margin-bottom: 0;
    padding: 11.5px 24px;
    border-top: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
  }

  .ant-descriptions-row {
    height: 42px;
    line-height: 42px;
  }
}

.sbdes2 {
  .ant-input {
    border: none !important;
    font-size: 14px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
    color: #1f1f20;
  }

  .ant-input[disabled] {
    background: #fff;
    cursor: default;
  }

  .ant-select {
    width: 100% !important;
    font-size: 14px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
    color: #1f1f20;
  }

  .ant-select-selector {
    border: none !important;
    font-size: 14px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
    color: #1f1f20;
  }

  .ant-picker {
    border: none !important;
    width: 100% !important;
    font-size: 14px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
    color: #1f1f20;
  }

  .ant-image-preview-wrap {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }

  .ant-descriptions-item-label {
    width: 170px;
    color: #95969A;
    font-size: 14px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
  }

  .ant-modal-title {
    font-size: 20px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Bold;
    font-weight: 700;
    color: #000000;
  }

  .ant-descriptions-header {
    font-size: 16px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Bold;
    font-weight: 700;
    color: #000000;
    background-color: #fafafa;
    margin-bottom: 0;
    padding: 11.5px 24px;
    border-top: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
  }

  .ant-descriptions-row {
    height: 42px;
    line-height: 42px;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #fff;
    color: #333;
  }

  .ant-picker.ant-picker-disabled {
    background-color: #fff;
  }

  .ant-picker-input>input[disabled] {
    color: #333;
  }
}

/* 下拉框图标 */
.ant-select-arrow {
  transform: scale(1.1, 0.8);
}

/* 弹窗X号 */
.ant-modal-close-icon {
  svg path {
    d: path("M851.416 217.84l-45.256-45.248L512 466.744l-294.152-294.16-45.256 45.256L466.744 512l-294.152 294.16 45.248 45.256L512 557.256l294.16 294.16 45.256-45.256L557.256 512z");
  }
}

// .ant-modal-close-x {
//   // transform: scale(1.1, 1);
// }

// path[AttributesStyle]{
//   d: path("M 851.416 217.84 l -45.256 -45.248 L 512 466.744 l -294.152 -294.16 l -45.256 45.256 L 466.744 512 l -294.152 294.16 l 45.248 45.256 L 512 557.256 l 294.16 294.16 l 45.256 -45.256 L 557.256 512 Z");
// }


// svg path{
//   fill: currentColor;
//   d:path("M 851.416 217.84 l -45.256 -45.248 L 512 466.744 l -294.152 -294.16 l -45.256 45.256 L 466.744 512 l -294.152 294.16 l 45.248 45.256 L 512 557.256 l 294.16 294.16 l 45.256 -45.256 L 557.256 512 Z");
// }


//设置表格头部竖向滚动宽度
.ant-table-header::-webkit-scrollbar {
  width: 4px;
}

//设置表格体滚动宽度
.ant-table-body,
.ant-table-body-inner {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    // background: #909090;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    // background: #EDEDED;
  }
}

//解决固定列底部预留的空隙
.ant-table-fixed-left,
.ant-table-fixed-right {
  .ant-table-body-outer {
    margin-bottom: 0 !important;
  }
}

/* 确认弹窗 两行文字 */
.ant-popover-message-title {
  p:nth-child(1) {
    height: 19px;
    font-size: 16px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.90);
    line-height: 21px;
  }

  p:nth-child(2) {
    height: 16px;
    font-size: 14px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.60);
    line-height: 22px;
  }
}

/* detailForm form.item灰色文字 */
.detailForm {
  .ant-typography {
    padding-bottom: 40px;
  }

  .ant-form-item-no-colon {
    color: #95969a;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .bcEdit {
    width: 64px;
    // line-height: 21px;
    height: 21px;
    border-radius: 3px;
    position: absolute;
    padding: 0;
    top: 6px;
    font-size: 12px;
    left: 150px;
  }

  .ant-divider-horizontal {
    margin: 70px 0;
  }
}


/* 数组件 */
.customTree {
  .ant-input[disabled] {
    color: #1f1f20 !important;
    cursor: pointer;
  }

  .ant-tree-node-selected {
    color: #0052d9 !important;
    background-color: #ECF2FE !important;

    .ant-input[disabled] {
      color: #0052d9 !important;
    }
  }
}

/* 图标下拉框 */
.selectIcon {
  .ant-select-item-empty {
    display: none !important;
  }
}

/* 抽屉 */
.ant-drawer-content-wrapper {
  .ant-drawer-header {
    border-bottom: none !important;

    .anticon-close {
      cursor: pointer;
    }

    svg path {
      d: path("M851.416 217.84l-45.256-45.248L512 466.744l-294.152-294.16-45.256 45.256L466.744 512l-294.152 294.16 45.248 45.256L512 557.256l294.16 294.16 45.256-45.256L557.256 512z");
    }
  }

  .ant-drawer-footer {
    border-top: none !important;
    margin-bottom: 21px;

    .ant-btn-default {
      border: none;
      font-size: 14px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.90);
      width: 88px;
      height: 32px;
    }

    .ant-btn-primary {
      background: #0052d9;
      border-radius: 3px;
      font-size: 14px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
      font-weight: 400;
      color: #ffffff;
      width: 88px;
      height: 32px;
    }
  }

  .drawerTitle {
    font-size: 20px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Bold;
    font-weight: 700;
    color: #000000;
  }

  .drawerSmallTitle {
    font-size: 14px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
    color: #95969a;
  }

  // .anticon{
  //   font-size: 20px;
  //   font-weight: bold;
  // }
}

/*  */
.BillingDetailsPage {
  h5 {
    font-size: 20px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Bold;
    font-weight: 700;
    color: #000000;
  }

  .txtPanels {
    .ant-space-item:first-child {
      // min-width: 80px;
      // width: 100px; 
      // max-width: 100px;
      margin-right: 10px;
    }
  }
}

/* confirmIcon1 */
.confirmIcon1 {
  float: left;
  margin-right: 10px;
}

.xxx {
  color: #1db280;
  color: #f1995e;
  color: #1db280;
  color: #e55c67;
  color: #f1995e;
  color: #1db280;
  color: #b9b9b9;
}
@primary-color: #0052D9;@layout-body-background: #f1f2f5;