/* html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
} */


html {
  /*统一行高*/
  line-height: 1.15;
  /*防止在winPhone和ISO中，因旋转屏幕而造成的字体大小的改变*/
}

body {
  /*去除margin*/
  margin: 0;
}

article,aside,footer,header,nav,section,figcaption,figure,main{
  /*重置IE8-浏览器的display*/
  display: block;
}

h1 {
  /*统一字体大小及margin*/
  font-size: 2em;
  margin: 0.67em 0;
}

figure {
  /*重置IE8浏览器的margin*/
  margin: 1em 40px;
}

hr {
  /*重置firefox浏览器的box-sizing*/
  box-sizing: content-box; 
  height: 0; 
  /*重置IE浏览器的overflow*/
  overflow: visible; 
}

pre {
  /*统一字体大小及字体系统*/
  font-family: monospace, monospace; 
  font-size: 1em; 
}

a {
  /*移除IE10中的灰色背景*/
  background-color: transparent; 
  -webkit-text-decoration-skip: objects; 
}

abbr[title] {
  /*移除Chrome57- and Firefox 39-中的border-bottom*/ 
  border-bottom: none; 
}

b,strong {
  /*统一字体重量*/
  font-weight: bolder;
}

code,kbd,samp {
  /*统一字体系列及字体大小*/
  font-family: monospace, monospace; 
  font-size: 1em; 
}

dfn {
  /*重置Android4.3-浏览器的字体样式*/ 
  font-style: italic;
}

mark {
  /*重置IE8-浏览器的背景颜色及文本颜色*/ 
  background-color: #ff0;
  color: #000;
}

small {
  /*统一字体大小*/
  font-size: 80%;
}

sub,sup {
  /*去除sub、sup对行高的影响*/
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  /*统一位置*/
  bottom: -0.25em;
}

sup {
  /*统一位置*/
  top: -0.5em;
}

audio,video {
  /*重置IE8-浏览器的display    */
  display: inline-block;
}

audio:not([controls]) {
  /*重置iOS 4-7中的display及height*/
  display: none;
  height: 0;
}

img {
  /*重置IE9-浏览器的border-style*/
  border-style: none;
}

svg:not(:root) {
  /*重置IE浏览器中的overflow*/
  overflow: hidden;
}

button,input,optgroup,select,textarea {
  /*统一样式*/
  /*移除Firefox and Safari中的margin*/
  font-family: sans-serif; 
  font-size: 100%; 
  line-height: 1.15; 
  margin: 0; 
}

button,input { 
  /*重置IE浏览器中的overflow*/
  overflow: visible;
}

button,select { 
  /*重置firefox浏览器中的text-transform*/
  text-transform: none;
}

button,html [type="button"], [type="reset"],[type="submit"] {
  /*重置webkit浏览器的appearance属性*/
  -webkit-appearance: button; 
}

button::-moz-focus-inner,[type="button"]::-moz-focus-inner,[type="reset"]::-moz-focus-inner,[type="submit"]::-moz-focus-inner {
  /*重置firefox浏览器中的border和padding*/
  border-style: none;
  padding: 0;
}

button:-moz-focusring,[type="button"]:-moz-focusring,[type="reset"]:-moz-focusring,[type="submit"]:-moz-focusring {
  /*统一outline*/ 
  outline: 1px dotted ButtonText;
}

fieldset {
  /*重置firefox浏览器的padding*/
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box; 
  color: inherit; 
  display: table; 
  max-width: 100%; 
  padding: 0; 
  white-space: normal; 
}

textarea {
  /*移除IE浏览器中默认的垂直滚动条*/
  overflow: auto;
}

[type="checkbox"],[type="radio"] {
  /*重置IE9-浏览器的box-sizing及padding    */
  box-sizing: border-box; 
  padding: 0; 
}

[type="number"]::-webkit-inner-spin-button,[type="number"]::-webkit-outer-spin-button {
  /*修正Chrome中增加和减量按钮的光标样式*/
  height: auto;
}

[type="search"] {
  /*重置Chrome and Safari的appearance和outline-offset*/
  -webkit-appearance: textfield; 
  outline-offset: -2px; 
}

[type="search"]::-webkit-search-cancel-button,[type="search"]::-webkit-search-decoration {
  /*在macOS上删除Chrome和Safari中的内填充和取消按钮。*/
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  /*在iOS和Safari中，纠正无法点击的类型。*/
  -webkit-appearance: button; 
  font: inherit; 
}

details, menu {
  /*重置IE8-浏览器的display*/
  display: block;
}

summary {
  /*统一display*/
  display: list-item;
}

canvas {
  /*重置IE8-浏览器的display*/
  display: inline-block;
}

template {
  /*重置IE浏览器的display*/    
  display: none;
}

[hidden] {
  /*重置IE9-浏览器的display    */
  display: none;
}

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
p{
  margin-bottom: 5px !important;
}
@primary-color: #0052D9;@layout-body-background: #f1f2f5;