body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'HarmonyOS Sans SC', 'HarmonyOS Sans SC-Medium',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.ellipsis-multiline3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em;
  /* 行高1.5em的三行 */
  line-height: 1.5em;
  height: 1.5em * 3;
}

.flex {
  display: flex;
}

.flex_space_between {
  display: flex;
  justify-content: space-between;
}

.flex_space_around {
  display: flex;
  justify-content: space-between;
}